<template>
  <div>
    <div class="text-center my-5">
      <button class="mybtn-blue m-3" @click="$router.push('/home')">
        home
      </button>
      <button class="mybtn m-3" @click="downloadPDF()" v-loading="isDownloading">
        Download As PDF
      </button>
    </div>

    <div id="invoiceContent" v-show="!isMobileOrTablet">
      <div class="header-banner">
        <img src="../../assets/phonebox/logo-white.png" alt="Phonebox Logo" class="main-image" />
        <div class="icon-container">
          <img src="../../assets/phonebox/logo-graphiconly.png" alt="PhoneBox Logo Icon" class="main-icon">
        </div>
      </div>
      <div class="header">
        <h4>PHONEBOX</h4>
        <h4>MOBILE WIRELESS SERVICE AGREEMENT</h4>
        <h4>(BRING YOUR OWN DEVICE)</h4>
      </div>
      <br />
      <table border="1" class="table-data">
        <tr>
          <td class="table-header" colspan="2">CUSTOMER INFORMATION</td>
          <td class="table-header" colspan="3">ACCOUNT INFORMATION</td>
        </tr>
        <tr>
          <td class="table-width-40" colspan="2">Name (individual or company): {{ agreementData.name }}</td>
          <td class="table-width-60" colspan="3">Transaction Type: {{ agreementData.transactionType }}</td>
        </tr>
        <tr>
          <td class="table-width-20" colspan="1" rowspan="5">Customer Address: <br /> {{ agreementData.address.customer
            }}</td>
          <td class="table-width-20" colspan="1" rowspan="5">Billing Address: <br /> {{ agreementData.address.billing }}
          </td>
          <td class="table-width-60" colspan="3">Account Number: {{ agreementData.accountNumber }}</td>
        </tr>
        <tr>
          <td class="table-width-60" colspan="3">Date of Agreement (MM/DD/YY): {{ agreementData.dateOfAgreement }}</td>
        </tr>
        <tr>
          <td class="table-width-60" colspan="3">Place of Agreement (City/Province): {{ agreementData.placeOfAgreement
            }}</td>
        </tr>
        <tr>
          <td class="table-width-60" colspan="3">Language Preference: {{ agreementData.lang }}</td>
        </tr>
        <tr>
          <td class="table-header" colspan="3">PAYMENT INFORMATION</td>
        </tr>
        <tr>
          <td class="table-width-40" colspan="2">{{ agreementData.address.isCustomerAndBillingAddressSame ? "▣" : "▢" }}
            Customer and Billing Address are the same</td>
          <td class="table-width-60" colspan="3">Billing choice (mail/email): {{ agreementData.billingChoice }}</td>
        </tr>
        <tr>
          <td class="table-width-40" colspan="2">Contact Name (if company): {{ agreementData.contactNameIfCompany }}
          </td>
          <td class="table-width-60" colspan="3">Pre-authorized payment options</td>
        </tr>
        <tr>
          <td class="table-width-40" colspan="2">Contact Title (if company): {{ agreementData.contactTitleIfCompany }}
          </td>
          <td class="table-width-60" colspan="3">I authorize PhoneBox to debit the total amount due each month from my:
          </td>
        </tr>
        <tr>
          <td class="table-width-40" colspan="2">Email Address: {{ agreementData.email }}</td>
          <td class="table-width-60" colspan="3">{{ agreementData.paymentMethod.paymentType.toLowerCase() === "bank" ?
            "▣" : "▢" }}
            Bank Account (pre-authorized chequing request form is attached)</td>
        </tr>
        <tr>
          <td class="table-width-40" colspan="2">Home Phone: {{ agreementData.homePhone }}</td>
          <td class="table-width-40" colspan="2">{{
            agreementData.paymentMethod.paymentType.toLowerCase() === "credit card" &&
              agreementData.paymentMethod.cardDetail.cardType === "visa" ? "▣" : "▢" }}
            Visa (last 4 digits): {{ agreementData.paymentMethod.cardDetail.cardType === "visa"
              ? agreementData.paymentMethod.cardDetail.last4Digit : "" }}</td>
          <td class="table-width-20" colspan="1">Expiry: {{ agreementData.paymentMethod.cardDetail.cardType === "visa" ?
            agreementData.paymentMethod.cardDetail.expiryDate : "" }}</td>
        </tr>
        <tr>
          <td class="table-width-40" colspan="2"></td>
          <td class="table-width-40" colspan="2">{{
            agreementData.paymentMethod.paymentType.toLowerCase() === "credit card" &&
              agreementData.paymentMethod.cardDetail.cardType === "master" ? "▣" : "▢" }}
            Mastercard (last 4 digits): {{ agreementData.paymentMethod.cardDetail.cardType === "master" ?
              agreementData.paymentMethod.cardDetail.last4Digit : "" }}</td>
          <td class="table-width-20" colspan="1">Expiry: {{ agreementData.paymentMethod.cardDetail.cardType === "master"
            ?
            agreementData.paymentMethod.cardDetail.expiryDate : "" }}</td>
        </tr>
        <tr>
          <td class="table-width-40" colspan="2"></td>
          <td class="table-width-40" colspan="2">{{
            agreementData.paymentMethod.paymentType.toLowerCase() === "credit card" &&
              agreementData.paymentMethod.cardDetail.cardType === "americanexpress" ? "▣" : "▢" }}
            Amex (last 4 digits): {{ agreementData.paymentMethod.cardDetail.cardType === "americanexpress" ?
              agreementData.paymentMethod.cardDetail.last4Digit : "" }}</td>
          <td class="table-width-20" colspan="1">Expiry: {{ agreementData.paymentMethod.cardDetail.cardType ===
            "americanexpress"
            ?
            agreementData.paymentMethod.cardDetail.expiryDate : "" }}</td>
        </tr>
        <tr>
          <td class="table-width-40" colspan="2"></td>
          <td class="table-width-60" colspan="3">{{ agreementData.paymentMethod.paymentType.toLowerCase() === "cash" ?
            "▣" : "▢" }}
            Cash / Cheque</td>
        </tr>
        <tr>
          <td class="table-header" colspan="5">{{ agreementData.isPostpaid ? "POSTPAID" : "PREPAID" }} SERVICE PLANS
            ("{{
              agreementData.isPostpaid ? "Postpaid" : "Prepaid" }} Service Plans")</td>
        </tr>
        <tr>
          <td colspan="2" class="table-width-40">Plan Name: {{ agreementData.plan.name }}</td>
          <td colspan="3" rowspan="5"></td>
        </tr>
        <tr>
          <td colspan="2" class="table-width-40">Plan Number: {{ agreementData.plan.number }}</td>
        </tr>
        <tr>
          <td colspan="2" class="table-width-40">Plan Category: {{ agreementData.plan.category }}</td>
        </tr>
        <tr>
          <td colspan="2" class="table-width-40">Monthly Service Fee: {{ agreementData.plan.monthlyServiceFee }}</td>
        </tr>
        <tr>
          <td colspan="2" class="table-width-40">Term: {{ agreementData.isPostpaid ? "Monthly" : "Prepaid" }}</td>
        </tr>
        <tr>
          <td colspan="5">
            {{ agreementData.isPostpaid ? "Postpaid" : "Prepaid" }} Plan Includes: <br />
            <div class="plan-features">
              <ul>
                <li>{{ agreementData.plan.data }} high speed 4G LTE/5G data</li>
                <li>UNLIMITED Canada wide calling</li>
                <li>UNLIMITED international text and picture messaging</li>
                <li>Voicemail - Up to 35 minutes of messages</li>
                <li>Up to 1,000 minutes of International Calling (List of countries for international calling available
                  at https://gophonebox.com/support.)</li>
                <li>Call Display</li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td class="table-header" colspan="5">{{ agreementData.isPostpaid ? "POSTPAID" : "PREPAID" }} SERVICE ADD-ONS
            ("{{ agreementData.isPostpaid ? "Postpaid" : "Prepaid" }} Service Add-ons")</td>
        </tr>
        <tr>
          <td colspan="1" class="table-width-20"><strong>Add-on Name</strong></td>
          <td colspan="1" class="table-width-20"><strong>Monthly Fee</strong></td>
          <td colspan="3"><strong>Description of Add-on:</strong></td>
        </tr>
        <tr v-show="!agreementData.plan.addOns.length">
          <td colspan="1" class="table-width-20 table-empty">N/A</td>
          <td colspan="1" class="table-width-20 table-empty">N/A</td>
          <td colspan="3" class="table-width-60 table-empty">N/A</td>
        </tr>
        <tr v-for="(addOn, index) in agreementData.plan.addOns" :key="index" v-show="agreementData.plan.addOns.length">
          <td colspan="1" class="table-width-20">{{ addOn.name }}</td>
          <td colspan="1" class="table-width-20">{{ addOn.monthlyFee }}</td>
          <td colspan="3" class="table-width-60">{{ addOn.desc }}</td>
        </tr>
        <tr>
          <td class="table-header" colspan="5">PROMOTIONS AND DISCOUNTS</td>
        </tr>
        <tr>
          <td colspan="1" class="table-width-20">Promotion Name</td>
          <td colspan="1" class="table-width-20">Amount</td>
          <td colspan="1" class="table-width-20">Duration</td>
          <td colspan="1" class="table-width-20">Start Date</td>
          <td colspan="1" class="table-width-20">End Date</td>
        </tr>
        <tr v-show="!agreementData.plan.promotions.length">
          <td colspan="1" class="table-width-20 table-empty">N/A</td>
          <td colspan="1" class="table-width-20 table-empty">N/A</td>
          <td colspan="1" class="table-width-20 table-empty">N/A</td>
          <td colspan="1" class="table-width-20 table-empty">N/A</td>
          <td colspan="1" class="table-width-20 table-empty">N/A</td>
        </tr>
        <tr v-for="(promotion, index) in agreementData.plan.promotions" :key="index"
          v-show="agreementData.plan.promotions.length">
          <td colspan="1" class="table-width-20">{{ promotion.name }}</td>
          <td colspan="1" class="table-width-20">{{ promotion.amount }}</td>
          <td colspan="1" class="table-width-20">{{ promotion.duration }}</td>
          <td colspan="1" class="table-width-20">{{ promotion.startDate }}</td>
          <td colspan="1" class="table-width-20">{{ promotion.endDate }}</td>
        </tr>
        <tr>
          <!-- <td colspan="2" class="table-header">
            ONE-TIME FEES
          </td> -->
          <td colspan="5" class="table-header">
            DATA OVERAGE FEES
          </td>
        </tr>
        <tr>
          <!-- <td colspan="1">One-time Fee Description</td> -->
          <!-- <td colspan="1">Amount</td> -->
          <td colspan="5">Per GB over data cap: {{ agreementData.overageFees.data }}</td>
        </tr>
        <tr>
          <!-- <td colspan="1" style="padding-bottom: 72px">{{ agreementData.oneTimeFee.desc }}</td>
          <td colspan="1" style="padding-bottom: 72px">{{ agreementData.oneTimeFee.amount }}</td> -->
          <td colspan="5">Your data services will be suspended if you reach $50 in overage fees in a single
            monthly billing cycle. To restore your data services for the remainder of the
            monthly billing cycle, you must add more data.</td>
        </tr>
        <tr>
          <td colspan="5" class="table-empty"></td>
        </tr>
        <tr>
          <td colspan="2" class="table-header">
            DEVICE INFORMATION
          </td>
          <td colspan="3" class="table-header">
            CANCELLATION CHARGES
          </td>
        </tr>
        <tr>
          <td colspan="2">Device Model: {{ agreementData.device.model }}</td>
          <td colspan="3" rowspan="3">Total Cancellation Charge: The lesser of $50 or 10% of the minimum monthly Fees
            for the remaining months of the contract, up to a maximum of 24 months.
            When your Fixed Term expires, the Cancellation Charge will be reduced to $0.00.</td>
        </tr>
        <tr>
          <td colspan="2">Device Colour: {{ agreementData.device.colour }}</td>
        </tr>
        <tr>
          <td colspan="2">Device Serial Number / IMEI: {{ agreementData.device.imei }}</td>
        </tr>
        <tr>
          <td colspan="2">SIM: {{ agreementData.device.sim }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="2" class="table-empty"></td>
          <td colspan="3" class="table-empty"></td>
        </tr>
        <tr>
          <td colspan="5" class="table-header">SUMMARY OF MONTHLY FEES</td>
        </tr>
        <tr>
          <td colspan="5">Monthly Fees - Service Plans: {{ agreementData.feesSummary.servicePlan }}</td>
        </tr>
        <tr>
          <td colspan="5">Monthly Fees - Add-Ons: {{ agreementData.feesSummary.addOns }}</td>
        </tr>
        <tr>
          <td colspan="5">Monthly GST/HST for Mobile Services: {{ agreementData.feesSummary.gstOrHst }}</td>
        </tr>
        <tr>
          <td colspan="5">Monthly PST/QST for Mobile Services: {{ agreementData.feesSummary.pstOrQst }}</td>
        </tr>
        <tr>
          <td colspan="5">Grand Total Monthly Fees and Taxes for Mobile Services: {{ agreementData.feesSummary.totalFees
            }}</td>
        </tr>
        <!-- <tr> -->
        <!-- <td colspan="5" class="table-empty table-width-100"></td> -->
        <!-- <td colspan="2" class="table-header">STORE INFORMATION</td> -->
        <!-- </tr> -->
        <!-- <tr> -->
        <!-- <td colspan="3">One-Time Fees for Mobile Services: {{ agreementData.oneTimeFee.amount }}</td> -->
        <!-- <td colspan="2">Sales Representative/Agent:</td> -->
        <!-- <td colspan="2"></td> -->
        <!-- </tr> -->
        <!-- <tr> -->
        <!-- <td colspan="3">Monthly GST/HST for Mobile Services: {{ agreementData.oneTimeFee.gstOrHst }}</td> -->
        <!-- <td colspan="2">Store Name:</td> -->
        <!-- <td colspan="2"></td> -->
        <!-- </tr> -->
        <!-- <tr> -->
        <!-- <td colspan="3">Monthly PST/QST for Mobile Services: {{ agreementData.oneTimeFee.pstOrQst }}</td> -->
        <!-- <td colspan="2">Store Address:</td> -->
        <!-- <td colspan="2"></td> -->
        <!-- </tr> -->
        <!-- <tr> -->
        <!-- <td colspan="3">Grand Total One-Time Fees and Taxes for Mobile Services: {{ agreementData.oneTimeFee.totalFees -->
        <!-- }}</td> -->
        <!-- <td colspan="2">Store Contact Number:</td> -->
        <!-- <td colspan="2"></td> -->
        <!-- </tr> -->
      </table>
      <br />
      <br />
      <p>
        This Service Agreement, including all information above, sets out specific terms and conditions applicable to
        PhoneBox&apos;s Mobile
        Wireless Services (“<b>Mobile Services</b>”). This Service Agreement forms part of the Agreement between you and
        PhoneBox, which also
        includes: (1) the Service Terms, which include PhoneBox Policies; (2) any other Service Agreements for other
        PhoneBox Services to
        which you have subscribed; and (3) any other PhoneBox document describing features, products or services and any
        other document
        incorporated by reference. You must read your Service Agreement alongside these other elements of the Agreement.
        By entering into this
        Service Agreement, you acknowledge that you have read, understood and agree to all of the details in your
        Agreement.
      </p>
      <br />
      <p>
        In the event of an inconsistency between this Service Agreement and other constituent documents of the
        Agreement, the inconsistency
        will be resolved by giving preference first to this Service Agreement(s), then to the Service Terms, then to any
        other PhoneBox
        documentation describing features, products or services and finally to any other documents incorporated by
        reference.
      </p>
      <br />
      <p>
        Unless otherwise defined herein, all capitalized terms in the Service Agreement have the meaning assigned to
        those terms in the Service
        Terms. Throughout this Service Agreement, “<b>Service Plans</b>” will refer collectively to any Postpaid Service
        Plans and Prepaid Service
        Plans described above and “<b>Add-Ons</b>” will refer collectively to any Postpaid Add-Ons and Prepaid Add-Ons
        described above.
      </p>
      <div style="break-after:page"></div>
      <div class="header-body">
        1.
        <span class="header-underline">How do I accept this Service Agreement?</span>
      </div>
      <p>
        You acknowledge that you have read, understood and agreed to this Service Agreement by either: (a) placing an
        order for Mobile Services
        by any means including online, over the phone or in-person; (b) activating Mobile Services on your device; or
        (c) using Mobile Services.
        If you do not agree to any element of the Agreement, you may not use Mobile Services.
      </p>
      <div class="header-body">
        2.
        <span class="header-underline">What Fees apply to Mobile Services?</span>
      </div>
      <p>
        You are responsible for all Fees described in this Service Agreement, including monthly charges, one-time
        charges, pay-per-use charges
        and the government fees described in Section 3 of this Service Agreement.
      </p>
      <div class="header-body">
        3.
        <span class="header-underline">Do any government fees apply to Mobile Services?</span>
      </div>
      <p>
        Where applicable, the following monthly government 9-1-1 fees apply on a per-line basis in addition to Fees:
        $0.52 in Québec, $2.08 in
        Saskatchewan, $0.95 in Alberta, $0.43 in Nova Scotia, $0.97 in New Brunswick, $0.70 in Prince Edward Island,
        $0.75 in Newfoundland
        and Labrador and $1.70 in the Northwest Territories.
      </p>
      <!-- <div class="header-body">
        4.
        <span class="header-underline">Do I have access to pay-per-use Services and if so, how does PhoneBox charge for
          those Services?</span>
      </div>
      <p>
        If any of the following Services are not included with your plans or add-ons, you may order them on a
        pay-per-use basis:
      </p>
      <ul>
        <li><b>Roaming:</b> When you leave the PhoneBox Mobile Service coverage area (as defined in Section 8 below)
          while remaining in
          Canada you can obtain mobile voice, data and text connectivity on the network of PhoneBox&apos;s domestic roaming
          partners.
          Mobile Services do not include international roaming service. Your domestic roaming usage will be subject to
          the following
          rates
          <br />
          <ul>
            <li>Voice:</li>
            <li>Data:</li>
            <li>Text:</li>
          </ul>
          Domestic roaming service will be suspended once roaming charges reach $100 in a single monthly billing cycle
          unless you
          consent to paying additional roaming charges.
        </li>
        <li>
          <b>Text, Picture and Video Messaging:</b> Pay-per-use Fees in the amount of $X.XX apply for each message sent
          or received and
          premium messages are subject to per-message Fees in the amount of $X.XX.
        </li>
        <li>
          <b>Long Distance Calling:</b> Mobile long distance calling is subject to the rates listed on the plan detail
          summary page.
        </li>
      </ul> -->
      <div class="header-body">
        4.
        <span class="header-underline">How is local and long distance airtime calculated?</span>
      </div>
      <p>
        Local and long distance airtime applies to all calls (completed or answered) starting when you press “call” or
        “send” until that
        call is ended and includes all ring time. Airtime is calculated in one-minute increments, rounded up to the next
        full minute on a
        per-call basis. You may be charged for roaming airtime whether or not the call is completed or answered.
      </p>
      <div class="header-body">
        5.
        <span class="header-underline">When will my calls be considered long distance?</span>
      </div>
      <p>
        A call will be considered long distance if it is outside of your local calling area, as determined by PhoneBox
        based on your
        location at the time of the call, your mobile phone number and the phone number called.
      </p>
      <div class="header-body">
        6.
        <span class="header-underline">How is my data usage calculated?</span>
      </div>
      <p>
        All data usage is rounded up to the next highest megabyte (“<b>MB</b>”) of data.
      </p>
      <div class="header-body">
        7.
        <span class="header-underline">What is PhoneBox&apos;s mobile service coverage area?</span>
      </div>
      <p>
        PhoneBox&apos;s mobile service coverage area is described on our Webpage at the following URL:
        <a href="https://gophonebox.com/coverage-map/" target="_blank">https://gophonebox.com/coverage-map/</a>.
      </p>
      <div class="header-body">
        8.
        <span class="header-underline">Will a Fixed Term be extended when it expires?</span>
      </div>
      <p>
        If you subscribe to a Fixed Term, the Term will automatically extend on a month-to-month basis at the expiry of
        the Fixed Term.
        PhoneBox will provide you notice at least 90 calendar days before the end of your Fixed Term whether or not the
        Service
        Agreement will be extended.
      </p>
      <div class="header-body">
        9.
        <span class="header-underline">Can my Service Agreement be changed?</span>
      </div>
      <p>
        We will only make changes to key contractual terms or conditions with your consent or if the change is to: (a)
        reduce a rate for
        Mobile Services; or (b) increase your usage allowance for Mobile Services. For changes to any other contractual
        terms, PhoneBox
        can change the Service Agreement pursuant to Section 5 of the Service Terms.
      </p>
      <br />
      <p>
        If you are subject to a Fixed Term, you may subscribe to additional Add-Ons or Service Plans of equal or greater
        value at any
        time subject to additional Fees, as applicable, without any change to the duration of your Fixed Term.
      </p>
      <div class="header-body">
        10.
        <span class="header-underline">Does a trial period apply to Mobile Services?</span>
      </div>
      <p>
        If your Mobile Services are subject to Cancellation Charges, you can cancel your Services at no cost within 15
        days of the date
        when you first received Mobile Services, as long as you did not use more than half the data of your permitted
        monthly usage
        limit.
      </p>
      <br />
      <p>
        If you identify as a person with a disability, you can cancel your Services at no cost within 30 days of the
        date when you first
        received Mobile Services, as long as you did not use more than twice the data of your permitted monthly usage
        limit.
      </p>
      <div>
        11.
        <span class="header-underline">What happens to my prepaid balance if the Term expires?</span>
      </div>
      <p>
        If you have a prepaid account with PhoneBox, you will have seven calendar days at the expiration of your Term,
        at no charge,
        to top up your balance to maintain an active account and retain any existing balance. If you do not top up your
        balance within
        this seven calendar day period, your Services and the Term will expire.
      </p>
      <div class="header-body">
        12.
        <span class="header-underline">Where can I find information about the Wireless Code?</span>
      </div>
      <p>
        The Wireless Code is a mandatory code of conduct for mobile wireless service providers. The Wireless Code sets
        out consumer
        rights and rules relating to mobile wireless services. Information about the CRTC&apos;s Wireless Code can be
        found
        on the following
        website:
        <a href="https://crtc.gc.ca/eng/phone/mobile/code.htm"
          target="_blank">https://crtc.gc.ca/eng/phone/mobile/code.htm</a>.
      </p>
      <div class="header-body">
        13.
        <span class="header-underline">What is the process if I have a complaint relating to Mobile Services or the
          Wireless Code?</span>
      </div>
      <p>
        Consistent with Section 42 of the Service Terms, if you have a dispute about Mobile Services, we want to resolve
        the dispute
        quickly and fairly and will work with you in good faith to do so. If you have tried to work with us but remain
        unsatisfied, then
        you have a right to escalate the dispute. Any unresolved dispute may be referred to voluntary single arbitrator
        arbitration. In such
        event, the fees for the arbitrator shall be shared equally by the parties.
      </p>
      <br />
      <p>
        If you are a consumer or small business with a monthly bill under $2500, you may escalate certain types of
        complaints relating
        to Mobile Services externally with the CCTS (www.ccts-cprst.ca, 1-888-221-1687). CCTS is the telecommunications
        consumer
        agency designated by the CRTC to resolve certain consumer and small business disputes about telecommunications
        and
        television services. CCTS accepts complaints relating to service delivery, contract disputes, billing, credit
        management and
        unauthorized transfers of service.
      </p>
      <div style="break-after:page"></div>
      <div class="header-body">
        14.
        <span class="header-underline">What warranties apply to my Device and Mobile Services?</span>
      </div>
      <p>
        Mobile Services require that you provide your own Device. We are not responsible for any compatibility issues
        between your
        Device and Mobile Services. All applicable warranties for Mobile Services are set out in Sections 16 and 17 of
        the Service
        Terms.
      </p>
      <div class="header-body">
        15.
        <span class="header-underline">What tools does PhoneBox make available to help with billing and Mobile Services
          management?</span>
      </div>
      <p>
        We provide text notifications relating to data usage and roaming. We also provide usage monitoring tools through
        PhoneBox&apos;s
        My Account portal on our Website. These tools allow you to check your prepaid usage balance.
      </p>
      <div class="header-body">
        16.
        <span class="header-underline">Can my phone number be transferred from or to another provider?</span>
      </div>
      <p>
        Yes. You must be the account holder of the number you wish to have transferred from or to another provider. By
        requesting a
        phone number transfer, you authorize PhoneBox to act as your agent when submitting cancellation or transfer
        requests to other
        providers.
      </p>
    </div>
  </div>
</template>

<script>
// import htmlToPdfWithoutPage from "../../utils/htmlToPdf";
import htmlToPdf from "../../utils/htmlToPdf";
import { mapAgreementResponseToObject } from "../../utils/utils";
export default {
  data() {
    return {
      isMobileOrTablet: false,
      isDownloading: false,
      dataCapacity: "",
      invoiceInfo: {},
      usageInfo: {},
      callInfo: {},
      rogersDailyDataUsage: {},
      //slice this data to two parts for two columns side-by-side display
      rogersDailyDataUsageFirstHalf: {},
      rogersDailyDataUsageSecondHalf: {},
      agreementData: {
        name: "",
        phoneNumber: "",
        address: {
          isCustomerAndBillingAddressSame: true,
          customer: "",
          billing: "",
        },
        transactionType: "",
        accountNumber: "",
        dateOfAgreement: "",
        placeOfAgreement: "",
        lang: "English",
        billingChoice: "Email",
        paymentMethod: {
          paymentType: "",
          cardDetail: {
            last4Digit: "",
            expiryDate: "",
            cardType: ""
          }, // Can be Null if Payment method is not Card
        },
        email: "",
        homePhone: "N/A",
        contactNameIfCompany: "N/A",
        contactTitleIfCompany: "N/A",
        isPostpaid: true,
        plan: {
          name: "",
          number: "",
          category: "",
          monthlyServiceFee: "",
          term: "",
          planIncludes: [],
          data: "",
          addOns: [
          ], // {  name: "",  monthlyFee: "",  desc: "",  } // Array of Add ons
          promotions: []  // { name: "", amount: "", duration: "", startDate: "", endDate: "", desc: ""} // Array of Promotions
        },
        // oneTimeFee: {
        //   desc: "",
        //   amount: "",
        //   gstOrHst: "",
        //   pstOrQst: "",
        //   totalFees: "",
        // },
        overageFees: {
          data: "",
        },
        device: {
          model: "",
          colour: "",
          imei: "",
          sim: "",
        },
        feesSummary: {
          servicePlan: "",
          addOns: "",
          gstOrHst: "",
          pstOrQst: "",
          totalFees: "",
        },
        storeInfo: {
          storeAgent: "",
          storeName: "",
          storeAddress: "",
          storeContact: "",
        },
        roamingCharges: {
          voice: "",
          data: "",
          text: "",
        },
        textPictureVideoMessagingFee: {
          original: "",
          premium: "",
        },
        longDistanceCalling: {
          url: "",
        }
      }
    };
  },
  methods: {
    downloadPDF() {
      this.isDownloading = true;
      let pdfFileName = "PhoneBox - Wireless Service Agreement";
      if (this.isMobileOrTablet) {
        this.isMobileOrTablet = false;
        setTimeout(() => {
          htmlToPdf.exportPDFWithoutPage(pdfFileName);
          this.isMobileOrTablet = true;
          this.isDownloading = false;
        }, 1500);
      } else {
        setTimeout(() => {
          htmlToPdf.exportPDFWithoutPage(pdfFileName);
          this.isDownloading = false;
        }, 1500);
      }
    },
    getAgreementData() {
      this.$axios
        .get("Account/GetServiceAggrementInfo")
        .then((response) => {
          this.agreementData = mapAgreementResponseToObject(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.isMobileOrTablet = window.innerWidth <= 1024;
    this.getAgreementData();
  },
};
</script>

<style lang="scss" scoped>
h2 {
  letter-spacing: 1px;
}

h3 {
  font-weight: 500;
  letter-spacing: 1px;
}

p {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

td {
  padding: 4px 8px;
  vertical-align: top
}

#invoiceContent {
  width: 1100px;
  margin: auto;
  padding: 200px 100px 100px 100px;
  margin-bottom: 50px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  position: relative;
}

.header-banner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #0594CA;
  width: 100%;
  height: 100px;
}

.header-banner .main-image {
  position: absolute;
  bottom: 20px;
  right: 100px;
}

.header-banner .icon-container {
  position: absolute;
  bottom: -50%;
  left: 100px;
  background-color: #0594CA;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header {
  text-align: center;
}

.text-white {
  color: #fff;
}

.text-main {
  color: #46cad3;
}

.text-blue {
  color: #2e9ed1;
}

.text-grey {
  color: #b1aeae;
}

.bg-main {
  background-color: #ebf8fa;
}

.bg-white {
  background-color: #fff;
}

.bg-blue {
  background-image: linear-gradient(to right, #197eb1, #44c3d1);
}

.mycard {
  border-radius: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.breakdownBar {
  width: 80%;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.callingInfoBar,
.usageInfoBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.callingInfoBar p,
.usageInfoBar p {
  width: 20%;
  font-weight: 400;
  text-align: center;
  margin: 10px 0px;
}

.callingInfoBarEachRow p {
  margin: 10px 0px;
  font-size: 12px;
  font-weight: 300;
}

.usageInfoBarEachRow p {
  margin: 10px 0px;
  font-size: 13px;
  font-weight: 300;
}

.specialBtn {
  border-radius: 50px;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: 0px;
  padding: 5px 20px;
  text-transform: uppercase;
}

.staticInfo p {
  font-weight: 300;
}

.mycol {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.table-data {
  width: 100%;
}

.table-half-col {
  width: 50%;
}

.table-quarter-col {
  width: 25%;
}

.table-fifth-col {
  width: 20%;
}

.table-two-fifth-col {
  width: 40%;
}

.table-width-10 {
  width: 10%;
}

.table-width-20 {
  width: 20%;
}

.table-width-30 {
  width: 30%;
}

.table-width-40 {
  width: 40%;
}

.table-width-50 {
  width: 50%;
}

.table-width-60 {
  width: 60%;
}

.table-width-70 {
  width: 70%;
}

.table-width-80 {
  width: 80%;
}

.table-width-90 {
  width: 90%;
}

.table-width-100 {
  width: 100%;
}

.table-empty {
  height: 33px;
}

.table-header {
  font-weight: 600;
}

.header-underline {
  text-decoration: underline;
  padding-left: 24px;
}

.header-body {
  font-weight: 600;
  margin: 18px 0px;
}

.plan-features ul {
  margin-bottom: 0;
}
</style>
